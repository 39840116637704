import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { IconWrapper } from './IconWrapper';

interface EmptyStateProps {
  readonly icon: ReactNode;
  readonly title: string;
  readonly description?: string;
  readonly action?: ReactNode;
}

export const EmptyState = ({ title, description, icon, action }: EmptyStateProps) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Stack alignItems="center">
        <IconWrapper size="l" color="greys.500">
          {icon}
        </IconWrapper>
        <Typography variant="body1" fontWeight={600} mt={4}>
          {title}
        </Typography>
        {description ? (
          <Typography variant="body2" color="greys.500" mt={1}>
            {description}
          </Typography>
        ) : null}
        {action ? (
          <Stack mt={6} direction="row" gap={2}>
            {action}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};
