import { InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useChangeSiteDomain } from 'api/site';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import isFQDN from 'validator/lib/isFQDN';
import { FormDialog } from '../base/dialogs/FormDialog';
import { CheckboxField } from '../base/forms/CheckboxField';
import { TextField } from '../base/forms/TextField';

interface Props {
  readonly domain: string;
  readonly siteId: string;
  readonly onClose: () => unknown;
}

interface ChangeDomainData {
  domain: string;
  agreement: boolean;
}

export function ChangeDomainDialog({ domain, onClose, siteId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isPending } = useChangeSiteDomain(siteId);
  const methods = useForm<ChangeDomainData>({
    defaultValues: {
      domain: domain.replace('www.', ''),
      agreement: false,
    },
  });
  const { reset } = methods;
  const { t } = useTranslation();

  const onSubmit = async (data: ChangeDomainData) => {
    if (!data.agreement) {
      enqueueSnackbar(t('change_domain_agreement_false_warning'), {
        key: 'mustAgreeToChangeDomain',
        variant: 'error',
      });
      return;
    }

    await mutateAsync({
      domain: data.domain,
    });
    onClose();
  };

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <FormDialog
      form={
        <Stack gap={4}>
          <TextField
            label={t('domain')}
            name="domain"
            placeholder={t('enter_your_domain_name_placeholder')}
            rules={{ required: true, validate: isFQDN }}
            startAdornment={
              <InputAdornment position="start" disableTypography variant="outlined">
                {t('http_optional_s')}
              </InputAdornment>
            }
            formatValue={val => val.replace(/https?:\/\/(www\.)?/, '')}
          />
          <CheckboxField
            name="agreement"
            label={t('change_domain_agreement_checkbox_label', { domain })}
          />
        </Stack>
      }
      methods={methods}
      onSubmit={onSubmit}
      onClose={handleClose}
      title={t('change_domain')}
      submitText={t('change')}
    />
  );
}
