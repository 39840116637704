import { Stack, Tooltip } from '@mui/material';
import { AccessLogRow } from 'api/siteMetrics';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import DesktopIcon from 'component/new_design/icons/Desktop.svg?react';
import PhoneIcon from 'component/new_design/icons/Phone.svg?react';

interface DeviceIpCellProps {
  readonly event: AccessLogRow;
}

export const DeviceIpCell = ({ event }: DeviceIpCellProps) => {
  const DeviceIcon = event.ClientDeviceType === 'desktop' ? DesktopIcon : PhoneIcon;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Tooltip title={event.ClientDeviceType}>
        <IconWrapper size="s" color="greys.500">
          <DeviceIcon />
        </IconWrapper>
      </Tooltip>
      <span>{'\u00b7'}</span>
      <span>{event.ClientIP}</span>
    </Stack>
  );
};
