import { useState } from 'react';
import { Button, Link, Stack } from '@mui/material';
import { FileManagerFile, useDeleteSiteFile, useSiteDetails, useSiteFileManager } from 'api/site';
import { Trans, useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { FileNameCell } from './FileNameCell';
import { FileBreadcrumbs } from './FileBreadcrumbs';
import CopyIcon from 'component/new_design/icons/Copy.svg?react';
import DownloadIcon from 'component/new_design/icons/Download.svg?react';
import EditIcon from 'component/new_design/icons/PencilEdit.svg?react';
import DeleteIcon from 'component/new_design/icons/Delete.svg?react';
import FolderIcon from 'component/new_design/icons/Folder.svg?react';
import FileIcon from 'component/new_design/icons/File.svg?react';
import copy from 'copy-to-clipboard';
import { downloadSitesFile } from 'utils/download';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { DropdownAction, DropdownMenu } from 'component/new_design/base/DropdownMenu';
import { CreateFileDialog } from './CreateFileDialog';
import { formatTableDate } from 'utils/dateFormat';
import { InfoTooltip } from 'component/new_design/base/InfoTooltip';
import { EmptyState } from 'component/new_design/base/EmptyState';
import FileSettings from 'component/new_design/icons/FileSettings.svg?react';
import { FileEditDialog } from '../../SiteDetails/FileManager/FileEditDialog';
import { useSiteFileDropzone } from 'component/hooks/useSiteFileDropzone';

interface FileManagerTableProps {
  readonly siteId: string;
}

export function FileManagerTable({ siteId }: FileManagerTableProps) {
  const siteDetails = useSiteDetails({ id: siteId });
  const deleteSiteFile = useDeleteSiteFile(siteId);

  const { t, i18n } = useTranslation();

  const tableState = usePaginatedListState(
    { filterKeys: ['path'] },
    { sortBy: 'name', sortDirection: 'asc', filters: { path: '' } }
  );

  const pathParam = tableState.params.filters?.path || '';

  const { data, isLoading } = useSiteFileManager(siteId, tableState);

  const [fileToDelete, setFileToDelete] = useState<FileManagerFile | null>(null);
  const [fileToEdit, setFileToEdit] = useState<FileManagerFile | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState<FileManagerFile['type'] | null>(null);

  const dropzone = useSiteFileDropzone(siteId, pathParam);

  const handleOpenFile = (file: FileManagerFile) => {
    if (file.type === 'dir') {
      tableState.setFilter('path', `${file.path}/${file.name}`);
    } else {
      setFileToEdit(file);
    }
  };

  const handleDeleteFile = async () => {
    if (!fileToDelete) {
      return;
    }

    await deleteSiteFile.mutateAsync({
      file: `${fileToDelete.path}/${fileToDelete.name}`,
    });
  };

  const handleFileCreated = () => {
    setCreateDialogOpen(null);
  };

  const totalRowCount = data?.data.metadata?.total || 0;
  const tableData = data?.data.result || [];

  const columns: (TableColumnType<FileManagerFile> | null)[] = [
    {
      label: t('name'),
      renderValue: file => <FileNameCell file={file} onClick={() => handleOpenFile(file)} />,
      key: 'name',
      sortable: true,
      width: 3,
    },
    {
      label: t('size'),
      key: 'size',
      renderValue: file => file.human_size,
      sortable: true,
    },
    {
      label: t('last_modified'),
      renderValue: file => formatTableDate(i18n.language, file.mtime! * 1000),
      key: 'mtime',
      sortable: true,
      width: 2,
    },
    {
      label: t('permissions'),
      key: 'mode',
    },
  ];

  const rowActions: TableRowActionType<FileManagerFile>[] = [
    {
      icon: <EditIcon />,
      label: t('view_edit'),
      onClick: handleOpenFile,
      hidden: file => file.type === 'dir',
    },
    {
      icon: <DownloadIcon />,
      label: t('download'),
      onClick: async file => {
        downloadSitesFile(siteId, file.name!, file.path!);
      },
      hidden: file => file.type === 'dir',
    },
    {
      icon: <CopyIcon />,
      label: t('copy_link'),
      onClick: file => {
        const domain = siteDetails.data?.data.result?.domain;
        const fileLink = `https://${domain}${file.path}/${file.name}`;
        copy(fileLink);
      },
    },
    {
      icon: <DeleteIcon />,
      label: t('delete'),
      onClick: setFileToDelete,
      color: 'reds.500',
    },
  ];

  const tableDropdownActions: DropdownAction[] = [
    {
      icon: <FolderIcon />,
      label: t('create_folder'),
      onClick: () => setCreateDialogOpen('dir'),
    },
    {
      icon: <FileIcon />,
      label: t('create_file'),
      onClick: () => setCreateDialogOpen('file'),
    },
  ];

  return (
    <div {...dropzone.getRootProps()}>
      <Table
        title={t('file_manager')}
        description={
          <Stack direction="row" alignItems="center">
            {t('file_manager_instructions_new')}
            <InfoTooltip size="small">
              <Trans
                i18nKey="file_manager_instructions_new_tooltip"
                components={[
                  <Link
                    key="0"
                    target="_blank"
                    href="https://support.rocket.net/hc/en-us/articles/4404145420699-How-to-Create-an-FTP-Account"
                  />,
                ]}
              />
            </InfoTooltip>
          </Stack>
        }
        label="Site List Table"
        columns={columns}
        data={tableData}
        totalRowCount={totalRowCount}
        isLoading={isLoading}
        state={tableState}
        searchPlaceholder={t('search_files')}
        enableSearch
        enablePagination
        rowActions={rowActions}
        tableBar={
          <FileBreadcrumbs path={pathParam} onChange={path => tableState.setFilter('path', path)} />
        }
        rowIdKey="name"
        emptyState={<EmptyState icon={<FileSettings />} title={t('no_data_yet')} />}
      >
        <Button variant="contained" color="primary" size="medium" onClick={() => dropzone.open()}>
          {t('upload')}
        </Button>
        <DropdownMenu actions={tableDropdownActions} />
      </Table>
      {fileToDelete ? (
        <ConfirmationDialog
          onClose={() => setFileToDelete(null)}
          onConfirm={handleDeleteFile}
          title={t(fileToDelete.type === 'file' ? 'delete_file' : 'delete_folder')}
          description={
            <Trans
              i18nKey={
                fileToDelete.type === 'file'
                  ? 'delete_file_description_new'
                  : 'delete_folder_description_new'
              }
              values={{ fileName: fileToDelete.name }}
              components={[<strong key="0" />]}
            />
          }
          confirmText={t('delete')}
          confirmColor="error"
        />
      ) : null}
      {fileToEdit ? <FileEditDialog file={fileToEdit} onClose={() => setFileToEdit(null)} /> : null}
      {createDialogOpen ? (
        <CreateFileDialog
          onClose={handleFileCreated}
          type={createDialogOpen}
          siteId={siteId}
          path={pathParam}
        />
      ) : null}
      <input {...dropzone.getInputProps()} />
    </div>
  );
}
