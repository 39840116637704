import Chat from '../icons/Chat.svg?react';
import Ticket from '../icons/Ticket.svg?react';
import { Button, Card, Stack, Typography } from '@mui/material';
import { Gravatar } from '../base/Gravatar';
import { useTranslation } from 'react-i18next';

export const SidebarSupport = () => {
  const { t } = useTranslation();

  const name = 'Ryan Flowers';
  const role = 'VIP Account Manager';

  return (
    <Card
      sx={{
        m: 3,
        p: 2,
        // Color does not change in dark mode
        backgroundColor: 'var(--color-grey-800)',
        color: 'white',
      }}
    >
      <Stack gap={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Gravatar size="l" name={name} />
          <Stack gap={0.5}>
            <Typography color="sidebar" variant="body2" fontWeight={600}>
              {name}
            </Typography>
            <Typography color="greys.400" variant="caption">
              {role}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <Button
            sx={{ height: 32 }}
            startIcon={<Chat />}
            variant="contained"
            color="sidebar"
            size="medium"
            fullWidth
          >
            {t('chat')}
          </Button>
          <Button
            sx={{ height: 32 }}
            startIcon={<Ticket />}
            onClick={() => {
              window.open('https://support.rocket.net', '_blank', 'noreferrer');
            }}
            variant="contained"
            color="sidebar"
            size="medium"
            fullWidth
          >
            {t('ticket')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
