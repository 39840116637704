import React, { useId } from 'react';
import { BaseDialog } from './BaseDialog';
import { LoadingButton } from '@mui/lab';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface FormDialogProps<T extends FieldValues> {
  readonly submitText: string;
  readonly open?: boolean;
  readonly title: React.ReactNode;
  readonly form: React.ReactNode;
  readonly onSubmit: (data: T) => unknown;
  readonly onClose?: () => unknown;
  readonly methods: UseFormReturn<T>;
}

export function FormDialog<T extends FieldValues>({
  submitText,
  title,
  form,
  onClose,
  onSubmit,
  open = true,
  methods,
}: FormDialogProps<T>) {
  const formId = useId();

  const { isSubmitting, isValid } = methods.formState;

  return (
    <BaseDialog
      onClose={isSubmitting ? undefined : onClose}
      open={open}
      title={title}
      content={
        <FormProvider {...methods}>
          <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
            {form}
          </form>
        </FormProvider>
      }
      actions={
        <LoadingButton
          data-testid="submitButton"
          color="primary"
          variant="contained"
          type="submit"
          form={formId}
          loading={isSubmitting}
          disabled={!isValid}
          size="large"
        >
          {submitText}
        </LoadingButton>
      }
    />
  );
}
