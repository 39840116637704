import React, { useState } from 'react';
import { ButtonProps, Typography } from '@mui/material';
import { BaseDialog } from './BaseDialog';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface Props {
  readonly open?: boolean;
  readonly isLoading?: boolean;
  readonly title: React.ReactNode;
  readonly description: React.ReactNode;
  readonly onConfirm: () => void | Promise<unknown>;
  readonly confirmText?: string;
  readonly confirmColor?: ButtonProps['color'];
  readonly onClose: () => unknown;
}

export function ConfirmationDialog({
  title,
  description,
  onClose,
  onConfirm,
  open = true,
  confirmText,
  confirmColor,
  isLoading,
}: Props) {
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  return (
    <BaseDialog
      onClose={submitting ? undefined : onClose}
      open={open}
      title={title}
      actions={
        <LoadingButton
          data-testid="confirmButton"
          color={confirmColor || 'primary'}
          variant="contained"
          size="large"
          onClick={async () => {
            setSubmitting(true);
            try {
              await onConfirm();
              onClose();
            } finally {
              setSubmitting(false);
            }
          }}
          loading={isLoading || submitting}
        >
          {confirmText || t('confirm')}
        </LoadingButton>
      }
      content={<Typography variant="body2">{description}</Typography>}
    />
  );
}
