import { Box, Stack } from '@mui/material';
import { CompactTabs } from 'component/new_design/base/CompactTabs';
import { linkHelper } from 'linkHelper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const Plugins = () => {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!siteId) {
      navigate(linkHelper.newDesign.sites.getLink());
    }
  }, [siteId, navigate]);

  if (!siteId) {
    return null;
  }

  return (
    <Stack gap={6}>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <CompactTabs
          label={t('category')}
          tabs={[
            {
              title: t('installed_plugins'),
              href: linkHelper.newDesign.sites.siteDetails.plugins.installed.getLink({ siteId }),
            },
            {
              title: t('discover_plugins'),
              href: linkHelper.newDesign.sites.siteDetails.plugins.discover.getLink({ siteId }),
            },
          ]}
        />
      </Box>
      <Outlet />
    </Stack>
  );
};

export default Plugins;
