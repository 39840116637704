import { IconButton, Popover, Box } from '@mui/material';
import { useState } from 'react';
import InformationCircle from 'component/new_design/icons/InformationCircle.svg?react';

interface Props {
  readonly size?: 'small' | 'medium';
  readonly children: React.ReactNode;
}

export const InfoTooltip = ({ children, size }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        size={size}
        onClick={event => setAnchorEl(event.currentTarget)}
        sx={{ color: 'greys.400', my: '-9px' }}
        edge="end"
      >
        <InformationCircle />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            maxWidth: '320px',
            padding: 3,
            backgroundColor: 'greys.900',
            color: 'common.white',
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
};
