import { useTheme } from '@mui/material';

export default function UserDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83333 11.6667C3.99238 11.6667 2.5 13.159 2.5 15C2.5 16.8409 3.99238 18.3333 5.83333 18.3333H14.1667C16.0076 18.3333 17.5 16.8409 17.5 15C17.5 13.159 16.0076 11.6667 14.1667 11.6667H5.83333Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M9.99996 1.875C7.81383 1.875 6.04163 3.64721 6.04163 5.83333C6.04163 8.01946 7.81383 9.79167 9.99996 9.79167C12.1861 9.79167 13.9583 8.01946 13.9583 5.83333C13.9583 3.64721 12.1861 1.875 9.99996 1.875Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
    </svg>
  );
}
