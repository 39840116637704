import { Chip, ChipProps } from '@mui/material';
import { Plugin } from 'api/plugins';
import { useTranslation } from 'react-i18next';

interface PluginStatusCellProps {
  readonly plugin: Plugin;
}

const useFormattedStatus = (plugin: Plugin) => {
  const { t } = useTranslation();

  switch (plugin.status) {
    case 'active':
      return t('active');
    case 'inactive':
      return t('inactive');
    case 'must-use':
      return t('must_use');
    default:
      return t('unknown');
  }
};

const getStatusColor = (plugin: Plugin): ChipProps['color'] => {
  switch (plugin.status) {
    case 'active':
      return 'success';
    case 'inactive':
      return 'default';
    case 'must-use':
    default:
      return 'primary';
  }
};

export const PluginStatusCell = ({ plugin }: PluginStatusCellProps) => {
  const label = useFormattedStatus(plugin);

  return <Chip color={getStatusColor(plugin)} size="medium" label={label} />;
};
