import { FileManagerFile, useCreateSiteFolder, useUploadSiteFile } from 'api/site';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { TextField } from 'component/new_design/base/forms/TextField';
import { Stack } from '@mui/material';

interface CreateFileDialogProps {
  readonly type: FileManagerFile['type'];
  readonly path: string;
  readonly siteId: string;
  readonly onClose: (success: boolean) => void;
}

export const CreateFileDialog = ({ type, path, siteId, onClose }: CreateFileDialogProps) => {
  const createSiteFolder = useCreateSiteFolder(siteId);
  const createSiteFile = useUploadSiteFile(siteId);

  const { t } = useTranslation();

  const methods = useForm<{ name: string }>({
    defaultValues: { name: '' },
  });

  const handleSubmit = async (values: { name: string }) => {
    if (type === 'dir') {
      await createSiteFolder.mutateAsync({ name: values.name, path });
    } else {
      await createSiteFile.mutateAsync({
        filename: values.name,
        path,
        content: '// Write file here',
      });
    }
    onClose(true);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <FormDialog
      form={
        <Stack>
          <TextField
            name="name"
            label={t('name')}
            placeholder={t(type === 'file' ? 'file_name' : 'folder_name')}
          />
        </Stack>
      }
      methods={methods}
      onSubmit={handleSubmit}
      onClose={handleClose}
      title={t(type === 'file' ? 'create_file' : 'create_folder')}
      submitText={t('create')}
    />
  );
};
