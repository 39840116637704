import { useTheme } from '@mui/material';

export default function MonitorDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13.3333C10.4602 13.3333 10.8333 13.7064 10.8333 14.1666V17.5H14.1667C14.6269 17.5 15 17.8731 15 18.3333C15 18.7936 14.6269 19.1666 14.1667 19.1666H5.83333C5.3731 19.1666 5 18.7936 5 18.3333C5 17.8731 5.3731 17.5 5.83333 17.5H9.16667V14.1666C9.16667 13.7064 9.53976 13.3333 10 13.3333Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M3.63627 0.833345C3.31205 0.833327 3.02024 0.833311 2.77749 0.853145C2.51751 0.874385 2.24128 0.922462 1.97053 1.06042C1.57853 1.26015 1.25982 1.57886 1.06008 1.97087C0.922126 2.24162 0.87405 2.51785 0.852809 2.77782C0.832975 3.02057 0.832991 3.31233 0.833009 3.63655V12.1968C0.832991 12.521 0.832975 12.8128 0.852809 13.0555C0.87405 13.3155 0.922126 13.5917 1.06008 13.8625C1.25982 14.2545 1.57853 14.5732 1.97053 14.7729C2.24128 14.9109 2.51751 14.959 2.77749 14.9802C3.02024 15.0001 3.31201 15 3.63624 15H16.3631C16.6873 15 16.9791 15.0001 17.2219 14.9802C17.4818 14.959 17.7581 14.9109 18.0288 14.7729C18.4208 14.5732 18.7395 14.2545 18.9393 13.8625C19.0772 13.5917 19.1253 13.3155 19.1465 13.0555C19.1664 12.8128 19.1664 12.521 19.1663 12.1968V3.63659C19.1664 3.31236 19.1664 3.02058 19.1465 2.77782C19.1253 2.51785 19.0772 2.24162 18.9393 1.97087C18.7395 1.57886 18.4208 1.26015 18.0288 1.06042C17.7581 0.922462 17.4818 0.874385 17.2219 0.853145C16.9791 0.833311 16.6874 0.833327 16.3631 0.833345H3.63627Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
    </svg>
  );
}
