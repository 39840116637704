import { useTheme } from '@mui/material';

export default function UserThreeDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16667 15.625C4.16667 13.4389 5.93887 11.6667 8.125 11.6667H11.875C14.0611 11.6667 15.8333 13.4389 15.8333 15.625C15.8333 17.1208 14.6208 18.3333 13.125 18.3333H6.875C5.37923 18.3333 4.16667 17.1208 4.16667 15.625Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M3.27076 13.2185C3.43114 12.8957 3.36584 12.5064 3.10892 12.2536C2.852 12.0008 2.4617 11.9419 2.14158 12.1075C0.871233 12.7647 0 14.0924 0 15.625C0 16.8738 0.844516 17.9233 1.99212 18.2374C2.29569 18.3205 2.62017 18.2255 2.83096 17.9918C3.04175 17.7581 3.10288 17.4255 2.98899 17.1321C2.80801 16.6658 2.70833 16.1581 2.70833 15.625C2.70833 14.7589 2.91101 13.9425 3.27076 13.2185Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M17.8584 12.1075C17.5383 11.9419 17.148 12.0009 16.8911 12.2536C16.6342 12.5064 16.5689 12.8957 16.7292 13.2185C17.089 13.9425 17.2917 14.7589 17.2917 15.625C17.2917 16.1581 17.192 16.6658 17.011 17.1321C16.8971 17.4255 16.9583 17.7581 17.169 17.9918C17.3798 18.2255 17.7043 18.3205 18.0079 18.2374C19.1555 17.9233 20 16.8738 20 15.625C20 14.0924 19.1288 12.7647 17.8584 12.1075Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M5.83366 5.83332C5.83366 3.53214 7.69914 1.66666 10.0003 1.66666C12.3015 1.66666 14.167 3.53214 14.167 5.83332C14.167 8.13451 12.3015 9.99999 10.0003 9.99999C7.69914 9.99999 5.83366 8.13451 5.83366 5.83332Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        d="M4.98586 3.28165C5.1518 2.95632 5.08656 2.56084 4.82493 2.30604C4.5633 2.05123 4.16624 1.99647 3.8454 2.17094C2.54956 2.87562 1.66699 4.2508 1.66699 5.83335C1.66699 7.41587 2.54954 8.79104 3.84536 9.49573C4.16619 9.67021 4.56325 9.61545 4.82489 9.36064C5.08652 9.10584 5.15176 8.71037 4.98583 8.38503C4.5957 7.62011 4.37533 6.75369 4.37533 5.83337C4.37533 4.91303 4.59572 4.04659 4.98586 3.28165Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        d="M16.1553 2.17094C15.8344 1.99647 15.4374 2.05123 15.1757 2.30604C14.9141 2.56085 14.8489 2.95632 15.0148 3.28166C15.4049 4.04659 15.6253 4.91303 15.6253 5.83337C15.6253 6.75368 15.4049 7.62011 15.0148 8.38502C14.8489 8.71036 14.9141 9.10584 15.1758 9.36064C15.4374 9.61544 15.8345 9.6702 16.1553 9.49573C17.4511 8.79104 18.3337 7.41587 18.3337 5.83335C18.3337 4.2508 17.4511 2.87562 16.1553 2.17094Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
    </svg>
  );
}
