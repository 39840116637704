import { ReactNode } from 'react';
import { Box, Breakpoint, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardTab from './CardTab';
import { Select } from '../base/forms/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconWrapper } from '../base/IconWrapper';

export type NavigationItem = {
  readonly duotoneIcon: (props: { readonly selected: boolean }) => JSX.Element;
  readonly solidIcon: ReactNode;
  readonly title: string;
  readonly to: string;
};

type Props = {
  readonly items: NavigationItem[];
  readonly breakpoint?: Breakpoint;
};
export default function Navigation({ items, breakpoint = 'md' }: Props) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint));
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedNavigationItem = items.find(item => pathname.startsWith(item.to));

  if (isSmallBreakpoint) {
    return (
      <Select
        variant="outlined"
        options={items.map(item => ({
          value: item.to,
          label: (
            <Stack gap={2} direction="row" alignItems="center">
              <IconWrapper
                color={selectedNavigationItem?.to === item.to ? 'common.black' : 'greys.400'}
                size="s"
              >
                {item.solidIcon}
              </IconWrapper>
              <Typography variant="body2">{item.title}</Typography>
            </Stack>
          ),
        }))}
        value={selectedNavigationItem?.to}
        onChange={to => navigate(to)}
        renderValue={() => (
          <Stack gap={2} direction="row" alignItems="center" py={1} px={0.5}>
            <IconWrapper size={18}>{selectedNavigationItem?.solidIcon}</IconWrapper>
            <Typography variant="body1" fontWeight={600}>
              {selectedNavigationItem?.title}
            </Typography>
          </Stack>
        )}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: `repeat(${items.length}, 1fr)`,
      }}
      component="nav"
      aria-label={t('settings_navigation')}
    >
      {items.map(item => (
        <CardTab key={item.to} IconComponent={item.duotoneIcon} title={item.title} to={item.to} />
      ))}
    </Box>
  );
}
