import { useTheme } from '@mui/material';

export default function FolderSearchDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.26844 2.72289C7.96829 2.63174 7.65152 2.63233 7.33662 2.6329L7.26166 2.633L6.96303 2.633C6.05987 2.63299 5.33141 2.63298 4.74151 2.68118C4.13414 2.7308 3.60065 2.83565 3.10707 3.08714C2.32306 3.48661 1.68564 4.12403 1.28617 4.90804C1.03468 5.40161 0.929838 5.9351 0.880213 6.54248C0.832017 7.13237 0.832023 7.86083 0.832031 8.76399V13.1687C0.832023 14.0718 0.832017 14.8003 0.880213 15.3902C0.929838 15.9976 1.03468 16.531 1.28617 17.0246C1.68564 17.8086 2.32306 18.4461 3.10707 18.8455C3.60065 19.097 4.13414 19.2019 4.74151 19.2515C5.33139 19.2997 6.05981 19.2997 6.96292 19.2997H13.0344C13.9375 19.2997 14.666 19.2997 15.2559 19.2515C15.8633 19.2019 16.3968 19.097 16.8903 18.8455C17.6743 18.4461 18.3118 17.8086 18.7112 17.0246C18.9627 16.531 19.0676 15.9976 19.1172 15.3902C19.1654 14.8003 19.1654 14.0719 19.1654 13.1688V11.264C19.1654 10.3609 19.1654 9.63235 19.1172 9.04248C19.0676 8.4351 18.9627 7.90161 18.7112 7.40804C18.3118 6.62403 17.6743 5.98661 16.8903 5.58714C16.3967 5.33565 15.8633 5.2308 15.2559 5.18118C14.666 5.13298 13.9375 5.13299 13.0344 5.133L11.0691 5.133C10.6258 5.133 10.5779 5.12451 10.5466 5.11502C10.4942 5.0991 10.4454 5.073 10.4031 5.03822C10.3779 5.01749 10.3442 4.98227 10.0983 4.61348L9.61914 3.89468L9.57764 3.83226C9.40345 3.56993 9.22821 3.30603 8.98588 3.10686C8.7743 2.93296 8.5305 2.80248 8.26844 2.72289Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83203 11.7995C6.83203 10.0491 8.24837 8.63281 9.99871 8.63281C11.749 8.63281 13.1654 10.0491 13.1654 11.7995C13.1654 12.4317 12.9798 13.0219 12.6594 13.5174L13.3868 14.2447C13.6471 14.5051 13.6471 14.9272 13.3868 15.1876C13.1264 15.4479 12.7043 15.4479 12.444 15.1876L11.7166 14.4602C11.2211 14.7806 10.6309 14.9661 9.99871 14.9661C8.24837 14.9661 6.83203 13.5498 6.83203 11.7995ZM9.99871 9.96615C8.98475 9.96615 8.16536 10.7855 8.16536 11.7995C8.16536 12.8134 8.98475 13.6328 9.99871 13.6328C10.5045 13.6328 10.9618 13.4293 11.2951 13.0959C11.6285 12.7626 11.832 12.3053 11.832 11.7995C11.832 10.7855 11.0127 9.96615 9.99871 9.96615Z"
        fill={selected ? theme.palette.primary.main : theme.palette.primary.light}
      />
    </svg>
  );
}
