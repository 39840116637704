import { Chip, IconButton, Stack, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import Copy from '../icons/Copy.svg?react';

export default function CopyValueCard({ value }: { readonly value?: string }) {
  if (!value) {
    return null;
  }

  return (
    <Chip
      sx={{
        height: '28px',
        fontFamily: 'Roboto Mono',
        alignItems: 'center',
        borderRadius: 'var(--border-radius)',
        backgroundColor: 'greys.50',
        '& > .MuiChip-label': {
          paddingRight: 0,
          paddingLeft: 2,
        },
      }}
      label={
        <Stack direction="row" gap={1}>
          <Typography fontWeight="400" variant="code" sx={{ marginTop: '6px' }}>
            {value}
          </Typography>
          <IconButton color="primary" onClick={() => copy(value)}>
            <Copy />
          </IconButton>
        </Stack>
      }
    />
  );
}
