import { Card, CardContent, Typography, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { JSX } from 'react';

export default function CardTab({
  IconComponent,
  title,
  to,
}: {
  readonly IconComponent: (props: { readonly selected: boolean }) => JSX.Element;
  readonly title: string;
  readonly to: string;
}) {
  const { pathname } = useLocation();
  const selected = pathname.startsWith(to);

  return (
    <Link
      style={{
        textDecoration: 'none',
      }}
      to={to}
      aria-current={selected ? 'page' : 'false'}
    >
      <Card
        sx={{
          minWidth: '65px',
          width: '100%',
          height: '100%',
          minHeight: '86px',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 3,
          justifyContent: 'space-between',
          backgroundColor: selected ? 'greys.900' : 'common.white',
          '[aria-current=false] &:hover': {
            backgroundColor: 'greys.100',
          },
          '[aria-current=false] &:active': {
            backgroundColor: 'greys.200',
          },
          'a[aria-current=false]:focus-visible &': {
            backgroundColor: 'greys.200',
          },
          'a[aria-current=page]:focus-visible &': {
            backgroundColor: 'greys.600',
          },
          transition: 'background-color 0.2s',
        }}
      >
        <IconComponent selected={selected} />
        <Typography
          variant="body2"
          fontWeight={selected ? 600 : 400}
          color={selected ? 'common.white' : 'greys.900'}
        >
          {title}
        </Typography>
      </Card>
    </Link>
  );
}
