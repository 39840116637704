import { BaseDialog } from 'component/new_design/base/dialogs/BaseDialog';
import { SuspenseLoader } from 'component/new_design/base/SuspenseLoader';
import { useTranslation } from 'react-i18next';
import Download from 'component/new_design/icons/Download.svg?react';
import { FileManagerFile, usePutSiteFile } from 'api/site';
import { lazy, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { downloadSitesFile } from 'utils/download';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { Box } from '@mui/material';

const FileEditor = lazy(
  () => import('component/new_design/partial/SiteDetails/FileManager/FileEditor')
);

interface FileEditDialogProps {
  readonly file: FileManagerFile;
  readonly onClose: () => unknown;
}

export const FileEditDialog = ({ file, onClose }: FileEditDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { siteId } = useParams<{ siteId: string }>();
  const saveFile = usePutSiteFile(siteId!);

  const [isDownloading, setIsDownloading] = useState(false);

  const editorRef = useRef<{ getValue: () => string }>(null);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await downloadSitesFile(siteId!, file.name!, file.path!);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSave = async () => {
    if (!editorRef.current) {
      enqueueSnackbar(null, { variant: 'error' });
      return;
    }

    await saveFile.mutateAsync({
      file: file.name!,
      path: file.path!,
      content: editorRef.current?.getValue(),
    });

    onClose();
  };

  return (
    <BaseDialog
      title={`${t('file_manager')} / "${file.name}"`}
      onClose={onClose}
      maxWidth="lg"
      content={
        <Box
          sx={{
            height: '50vh',
            display: 'grid',
            overflow: 'auto',
            borderRadius: 'var(--border-radius-m)',
          }}
        >
          <SuspenseLoader>
            <FileEditor file={file} ref={editorRef} />
          </SuspenseLoader>
        </Box>
      }
      actions={
        <>
          <LoadingButton
            size="large"
            color="secondary"
            variant="contained"
            startIcon={<Download />}
            onClick={handleDownload}
            loading={isDownloading}
          >
            {t('download')}
          </LoadingButton>
          <LoadingButton
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSave}
            loading={saveFile.isPending}
          >
            {t('save')}
          </LoadingButton>
        </>
      }
    />
  );
};
