import { ThemeProvider, useMediaQuery } from '@mui/material';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react';
import { onCreateTheme, StoredColorMode } from '../theme';
import { usePersistedState } from 'component/hooks/usePersistedState';

export const ColorModeContext = createContext<
  [StoredColorMode, Dispatch<SetStateAction<StoredColorMode>>]
>(['light', () => {}]);

export const AutoThemeProvider = ({ children }: PropsWithChildren) => {
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  const colorModeState = usePersistedState<StoredColorMode>('light', 'color-mode');

  const colorMode = colorModeState[0];

  const theme = useMemo(
    () => onCreateTheme(colorMode === 'auto' ? systemTheme : colorMode),
    [colorMode, systemTheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <ColorModeContext.Provider value={colorModeState}>{children}</ColorModeContext.Provider>
    </ThemeProvider>
  );
};
