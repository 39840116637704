import { Stack } from '@mui/material';
import { Flag } from 'component/new_design/base/Flag';
import { useMemo } from 'react';

interface Props {
  readonly code: string;
  readonly label?: string;
}

export const LocationCell = ({ code, label }: Props) => {
  const displayedLabel = useMemo(() => {
    if (label) {
      return label;
    }

    const fallback = code.toUpperCase();

    try {
      return new Intl.DisplayNames(['en'], { type: 'region' }).of(code) || fallback;
    } catch {
      return fallback;
    }
  }, [code, label]);

  const flagCode = code === 'GB' ? 'GB-UKM' : code.toUpperCase();

  return (
    <Stack direction="row" gap={2.5} alignItems={'center'}>
      <Flag code={flagCode} />
      <span>{displayedLabel}</span>
    </Stack>
  );
};
