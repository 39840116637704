import { useTheme } from '@mui/material';

export default function CreditCardDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1666 8.33334H0.833439L0.833374 11.369C0.833366 12.2721 0.83336 13.0007 0.881555 13.5905C0.93118 14.1979 1.03602 14.7314 1.28751 15.225C1.68699 16.009 2.32441 16.6464 3.10841 17.0459C3.60199 17.2974 4.13548 17.4022 4.74285 17.4518C5.33273 17.5 6.06115 17.5 6.96426 17.5H13.0357C13.9388 17.5 14.6674 17.5 15.2572 17.4518C15.8646 17.4022 16.3981 17.2974 16.8917 17.0459C17.6757 16.6464 18.3131 16.009 18.7126 15.225C18.9641 14.7314 19.0689 14.1979 19.1185 13.5905C19.1667 13.0006 19.1667 12.2722 19.1667 11.369L19.1666 8.33334ZM4.87504 10C4.4148 10 4.04171 10.3731 4.04171 10.8333C4.04171 11.2936 4.4148 11.6667 4.87504 11.6667H7.62504C8.08528 11.6667 8.45837 11.2936 8.45837 10.8333C8.45837 10.3731 8.08528 10 7.62504 10H4.87504Z"
        fill={selected ? theme.palette.common.white : theme.palette.primary.main}
      />
      <path
        d="M13.0357 2.5C13.9388 2.49999 14.6673 2.49999 15.2572 2.54818C15.8645 2.59781 16.398 2.70265 16.8916 2.95414C17.6756 3.35361 18.313 3.99103 18.7125 4.77504C18.964 5.26862 19.0688 5.80211 19.1185 6.40948C19.1252 6.49244 19.1311 6.57813 19.1361 6.66667H0.863892C0.868897 6.57813 0.874722 6.49244 0.8815 6.40948C0.931124 5.80211 1.03597 5.26862 1.28746 4.77504C1.68693 3.99103 2.32435 3.35361 3.10836 2.95414C3.60193 2.70265 4.13542 2.59781 4.7428 2.54818C5.3327 2.49999 6.06116 2.49999 6.96431 2.5H13.0357Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
}
