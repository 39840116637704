import { useMemo } from 'react';
import { PaginatedListParams } from './usePaginatedListState';

type LoadingState = 'loading' | 'noResults' | 'noData' | 'hasData';

export const usePaginatedListLoadingState = (
  hasData: boolean,
  isLoading: boolean,
  params: PaginatedListParams
) => {
  const loadingState: LoadingState = useMemo(() => {
    if (isLoading) {
      return 'loading';
    } else if (
      !hasData &&
      (params.search || Object.keys(params.filters).filter(filter => filter !== 'duration').length)
    ) {
      return 'noResults';
    } else if (!hasData) {
      return 'noData';
    } else {
      return 'hasData';
    }
  }, [hasData, isLoading, params]);

  return loadingState;
};
