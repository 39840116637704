import { InputAdornment, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FtpAccount, UpdateFtpAccountData, useUpdateFtpAccount } from 'api/ftpAccounts';
import { useParams } from 'react-router-dom';
import { TextField } from '../base/forms/TextField';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import passwordStrength from 'password-strength-calc';
import { FormDialog } from '../base/dialogs/FormDialog';

interface Props {
  readonly ftpAccount: FtpAccount;
  readonly onClose: () => unknown;
  readonly onSuccess: () => Promise<unknown>;
}

export function UpdateFtpAccount({ ftpAccount, onClose, onSuccess }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const updateFtpAccount = useUpdateFtpAccount({
    id: siteId,
    username: ftpAccount.user ?? '',
  });
  const existingRelDir = ftpAccount.reldir?.replace('public_html/', '').replace('public_html', '');
  const { t } = useTranslation();

  const methods = useForm<
    UpdateFtpAccountData & {
      username: string;
    }
  >({
    defaultValues: {
      username: ftpAccount.user,
      new_password: '',
      homedir: existingRelDir,
    },
  });

  const { reset } = methods;
  const onSubmit = async (data: UpdateFtpAccountData) => {
    const changedValues: { new_password?: string; homedir?: string } = {};

    if (data.new_password) {
      changedValues.new_password = data.new_password;

      if (passwordStrength(changedValues.new_password) < 60) {
        enqueueSnackbar(t('ftp_account_password_error'), {
          key: 'passwordStrengthError',
          variant: 'error',
        });
        return;
      }
    }
    if (data.homedir !== existingRelDir) {
      changedValues.homedir = data.homedir ? `public_html/${data.homedir}` : 'public_html';
    }

    await updateFtpAccount.mutateAsync(changedValues);
    await onSuccess();
    reset();
    onClose();
  };

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <FormDialog
      form={
        <Stack spacing={4} direction="column">
          <TextField fullWidth name="username" label="Username" disabled />
          <TextField
            defaultValue=""
            name="new_password"
            fullWidth
            label="Password"
            type="password"
          />
          <TextField
            defaultValue=""
            name="homedir"
            fullWidth
            label="Directory"
            startAdornment={
              <InputAdornment position="start" disableTypography>
                {'public_html/'}
              </InputAdornment>
            }
          />
        </Stack>
      }
      methods={methods}
      onSubmit={onSubmit}
      onClose={handleClose}
      title={t('update_ftp_account')}
      submitText={t('update')}
    />
  );
}
