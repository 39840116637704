import { Box, Button, Link, Stack, Typography } from '@mui/material';
import ChevronLeft from '../icons/ChevronLeft.svg?react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
  readonly to: string;
}

export function BackButton({ to }: BackButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigate(to);
  };

  return (
    <Box>
      <Button
        href={to}
        onClick={handleClick}
        variant="text"
        size="large"
        startIcon={<ChevronLeft />}
      >
        {t('back')}
      </Button>
    </Box>
  );
}
