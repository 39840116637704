import { useState } from 'react';
import Plus from '../icons/plus.svg?react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../base/dialogs/FormDialog';
import { Button, Stack, Typography } from '@mui/material';
import { TextField } from '../base/forms/TextField';
import { InviteUserData, useInviteUser } from 'api/users';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'component/hooks/useSnackbar';

export default function InviteUser() {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useInviteUser();
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const methods = useForm<InviteUserData>({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
    },
  });

  function handleClose() {
    methods.reset();
    setShowAddDialog(false);
  }

  const onSubmit = async (data: InviteUserData) => {
    await mutateAsync(data);
    methods.reset();
    enqueueSnackbar(t('user_invited_successfully'), {
      key: 'inviteUser',
      variant: 'success',
    });
    setShowAddDialog(false);
  };

  return (
    <>
      <FormDialog
        form={
          <Stack spacing={4} direction="column">
            <TextField
              name="firstname"
              label={t('first_name')}
              disabled={isPending}
              fullWidth
              rules={{ required: true }}
            />
            <TextField
              name="lastname"
              label={t('last_name')}
              disabled={isPending}
              fullWidth
              rules={{ required: true }}
            />
            <TextField
              name="email"
              disabled={isPending}
              fullWidth
              label={t('email')}
              rules={{ required: true }}
            />
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={
          <Stack>
            <Typography variant="body1" fontWeight={600} color="greys.900">
              {t('invite_user')}
            </Typography>
            <Typography variant="body2" color="greys.500">
              {t('invite_user_details')}
            </Typography>
          </Stack>
        }
        submitText={t('invite')}
      />
      <Button
        onClick={() => {
          setShowAddDialog(true);
        }}
        size="medium"
        variant="contained"
        color="primary"
        startIcon={<Plus />}
      >
        {t('invite_user')}
      </Button>
    </>
  );
}
