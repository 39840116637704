import { Link, Rating, Skeleton, Stack, Typography } from '@mui/material';
import { NewPlugin, useInstallPlugin, useSearchPlugins } from 'api/plugins';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { Grid } from 'component/new_design/base/Grid/Grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IconWrapper } from 'component/new_design/base/IconWrapper';
import PluginIcon from 'component/new_design/icons/PluginAddon.svg?react';
import ShareIcon from 'component/new_design/icons/Share.svg?react';
import StarIcon from 'component/new_design/icons/Star.svg?react';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import he from 'he';
import { Select } from 'component/new_design/base/forms/Select';

export const PluginsGrid = () => {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();

  const gridState = usePaginatedListState(
    { perPageOptions: [12, 48, 96], filterKeys: ['query'] },
    { perPage: 12 }
  );
  const plugins = useSearchPlugins(gridState, siteId!);
  const installPlugin = useInstallPlugin(siteId!);

  const [installingPlugin, setInstallingPlugin] = useState<string | null>(null);

  const handleInstallClick = (plugin: NewPlugin) => async () => {
    setInstallingPlugin(plugin.slug!);
    try {
      await installPlugin.mutateAsync({
        plugins: plugin.slug,
        activate: true,
      });
    } finally {
      setInstallingPlugin(null);
    }
  };

  const getSrcSet = (icons: NewPlugin['icons']) => {
    return (['1x', '2x'] as const)
      .filter(size => icons?.[size])
      .map(size => `${icons?.[size]} ${size}`)
      .join(', ');
  };

  const categories = [
    { label: t('caching'), value: 'caching' },
    { label: t('optimization'), value: 'optimization' },
    { label: t('ecommerce'), value: 'ecommerce' },
  ];

  return (
    <Grid
      title={t('discover_plugins')}
      data={plugins.data?.data.result || []}
      totalRowCount={plugins.data?.data.metadata?.total || 0}
      isLoading={plugins.isLoading}
      state={gridState}
      searchPlaceholder={t('search_plugins')}
      enableSearch
      enablePagination
      emptyState={<EmptyState icon={<PluginIcon />} title={t('no_plugins_available')} />}
      rowIdKey="name"
      renderItem={plugin => (
        <Stack gap={6}>
          <Stack gap={4}>
            <Stack gap={4} direction="row">
              <img alt={plugin.name} srcSet={getSrcSet(plugin.icons)} width={40} height={40} />
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {he.decode(plugin.name!)}
              </Typography>
              <Link
                sx={{
                  ml: 'auto',
                }}
                href={plugin.homepage}
                target="_blank"
              >
                <IconWrapper size="s" color="blues.500">
                  <ShareIcon />
                </IconWrapper>
              </Link>
            </Stack>
            <Stack gap={1}>
              <Rating
                value={(plugin.rating! / 100) * 5}
                readOnly
                icon={
                  <IconWrapper size={12} color="oranges.500">
                    <StarIcon />
                  </IconWrapper>
                }
                emptyIcon={
                  <IconWrapper size={12} color="oranges.100">
                    <StarIcon />
                  </IconWrapper>
                }
              />
              <Typography variant="caption" color="greys.500">
                {t('rating', { count: plugin.num_ratings })}
                {' \u00b7 '}
                {t('active_install', { count: plugin.active_installs })}
              </Typography>
              <Typography variant="body2" color="greys.500">
                {he.decode(plugin.short_description!)}
              </Typography>
            </Stack>
          </Stack>
          <LoadingButton
            sx={{ mt: 'auto', alignSelf: 'flex-start' }}
            variant="contained"
            color="secondary"
            loading={installingPlugin === plugin.slug}
            onClick={handleInstallClick(plugin)}
          >
            {t('install')}
          </LoadingButton>
        </Stack>
      )}
      renderItemLoader={() => <Skeleton height={224} />}
    >
      <Select
        options={categories}
        value={gridState.params.filters.query}
        onChange={value => gridState.setFilter('query', value)}
        enableEmptyOption
        emptyOptionLabel={t('all')}
      />
    </Grid>
  );
};
