import { Link, Tooltip } from '@mui/material';

interface URLCellProps {
  readonly url: string;
  readonly text?: string;
}

export const URLCell = ({ url, text }: URLCellProps) => {
  return (
    <Tooltip title={url}>
      <Link
        href={url}
        sx={{
          color: 'greys.900',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        target="_blank"
      >
        {text || url}
      </Link>
    </Tooltip>
  );
};
