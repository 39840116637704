import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, IconButton, Tooltip } from '@mui/material';
import Copy from '../icons/Copy.svg?react';
import { useTranslation } from 'react-i18next';

const COPIED_TOOLTIP_DURATION = 1000;

interface CopyButtonProps {
  readonly value: string;
  readonly hideText?: boolean;
  readonly disabled?: boolean;
}

export const CopyButton = ({ value, hideText, disabled }: CopyButtonProps) => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCopy = () => {
    copy(value);
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, COPIED_TOOLTIP_DURATION);
  };

  return (
    <Tooltip
      title="Copied!"
      placement="top"
      open={show}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      {!hideText ? (
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleCopy}
          startIcon={<Copy />}
          disabled={disabled}
        >
          {t('copy')}
        </Button>
      ) : (
        <IconButton color="primary" size="small" onClick={handleCopy} disabled={disabled}>
          <Copy />
        </IconButton>
      )}
    </Tooltip>
  );
};
