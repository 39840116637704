import { Card, CardContent, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export const NoteCard = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ backgroundColor: 'greys.50' }}>
      <CardContent sx={{ padding: 4 }}>
        <Stack gap={1}>
          <Typography variant="body2" fontWeight={600}>
            {t('note')}
          </Typography>
          <Typography variant="body2" color="greys.500">
            {children}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
