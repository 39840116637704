import { Box } from '@mui/material';
import { Header } from '../partial/Header';
import { SiteUserHeader } from '../partial/SiteUserHeader';
import { decodeToken, getUserSession, isOldStyleToken, isSiteAccess } from 'utils/token';
import { isInIframe } from 'utils/iframe';
import { useLocation } from 'react-router-dom';
import { ReactNode, useContext } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { getIsWpSiteControl } from 'utils';
import { linkHelper } from 'linkHelper';
import { Token } from 'api/types';
import Cookie from 'js-cookie';
import qs from 'query-string';

export default function Authenticated({
  hideHeader,
  children,
}: {
  readonly hideHeader?: boolean;
  readonly children: ReactNode;
}) {
  const { pathname, search } = useLocation();
  const isIframe = isInIframe();
  let userSession = getUserSession();
  const qsParams = qs.parse(window.location.search);
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const token = userSession && decodeToken(userSession);
  const isWpSiteControl = getIsWpSiteControl();
  const loginURL = `${linkHelper.login.getLink()}?retUrl=${pathname}${search}`;

  if (token && isOldStyleToken(token as Token)) {
    Cookie.remove('user_session');
    window.location.href = `${window.location.origin}/login`;
  }

  if (typeof qsParams.token === 'string') {
    if (isIframe) {
      window.sessionStorage.setItem('user_session', qsParams.token);
    } else {
      Cookie.set('user_session', qsParams.token as string, {
        expires: 3560,
        sameSite: 'strict',
      });
    }
    userSession = qsParams.token;
  }

  if (!userSession && !isWpSiteControl) {
    window.location.href = loginURL;
  }

  if (typeof qsParams.token === 'string' || userSession) {
    return (
      <Box>
        {(!sdk || sdk?.header === 'show') &&
        !hideHeader &&
        token &&
        !isWpSiteControl &&
        !isSiteAccess(token as Token) ? (
          <Header hasData />
        ) : null}
        {isWpSiteControl ? <SiteUserHeader /> : null}
        {children}
      </Box>
    );
  }

  if (pathname !== linkHelper.login.getLink()) {
    window.location.href = loginURL;
  }

  return null;
}
