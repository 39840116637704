import Dashboard from '../icons/Dashboard.svg?react';
import Monitor from '../icons/Monitor.svg?react';
import Chatting from '../icons/ChatChatting.svg?react';
import { Link, useLocation } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { IconWrapper } from '../base/IconWrapper';
import { ReactNode } from 'react';

interface SidebarNavigationItemProps {
  readonly to: string;
  readonly icon: ReactNode;
  readonly text: string;
}

const SidebarNavigationItem = ({ to, icon, text }: SidebarNavigationItemProps) => {
  const { pathname } = useLocation();

  const isExternal = to.startsWith('http');
  const isActive = pathname.startsWith(to);

  return (
    <Box component="li" sx={{ a: { textDecoration: 'none' } }}>
      <Link
        to={to}
        target={isExternal ? '_blank' : '_self'}
        aria-current={isActive ? 'page' : 'false'}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            px: 4,
            py: 3,
            color: isActive ? 'common.white' : 'greys.400',
            backgroundColor: isActive ? 'blues.500' : 'transparent',
            'a[aria-current=false] &:hover': {
              // Color does not change in dark mode
              backgroundColor: 'var(--color-grey-800)',
            },
            'a[aria-current=false]:focus-visible &': {
              // Color does not change in dark mode
              backgroundColor: 'var(--color-grey-800)',
            },
            'a[aria-current=page]:focus-visible &': {
              backgroundColor: 'blues.400',
            },
            transition: 'background-color 0.2s, color 0.2s',
            borderRadius: 'var(--border-radius)',
          }}
        >
          <IconWrapper size="s">{icon}</IconWrapper>
          <Typography variant="body2">{text}</Typography>
        </Stack>
      </Link>
    </Box>
  );
};

export const SidebarNavigation = () => {
  const { t } = useTranslation();
  return (
    <nav>
      <Stack component="ul" px={3}>
        <SidebarNavigationItem
          to={linkHelper.newDesign.dashboard.getLink()}
          icon={<Dashboard />}
          text={t('dashboard')}
        />
        <SidebarNavigationItem
          to={linkHelper.newDesign.sites.getLink()}
          icon={<Monitor />}
          text={t('sites')}
        />
        <SidebarNavigationItem
          to="https://support.rocket.net"
          icon={<Chatting />}
          text={t('support')}
        />
      </Stack>
    </nav>
  );
};
