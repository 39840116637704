import { createTheme } from '@mui/material/styles';
import { cssVar, important } from 'utils/css';
import { PaletteMode } from '@mui/material';
import { createLightPalette } from './palettes/light';
import { createDarkPalette } from './palettes/dark';
import { BASE_SPACING } from './constants';
import { getSpacing } from './palettes/utils';

export type StoredColorMode = 'light' | 'dark' | 'auto';

declare module '@mui/material/styles' {
  interface Palette {
    greys: Palette['grey'];
    blues: Palette['grey'];
    greens: Palette['grey'];
    oranges: Palette['grey'];
    reds: Palette['grey'];
    purple: Palette['grey']['500'];
    violet: Palette['grey']['500'];
    // TODO - need to figure this out, might be able to use a different option
    sidebar?: Palette['primary'];
    header?: Palette['primary'];
    tertiary?: Palette['primary'];
  }

  interface PaletteOptions {
    greys?: PaletteOptions['grey'];
    blues?: PaletteOptions['grey'];
    greens?: PaletteOptions['grey'];
    oranges?: PaletteOptions['grey'];
    purple?: Palette['grey']['500'];
    violet?: Palette['grey']['500'];
    // TODO - need to figure this out, might be able to use a different option
    sidebar?: PaletteOptions['primary'];
    header?: PaletteOptions['primary'];
    tertiary?: Palette['primary'];
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    header: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sidebar: true;
    header: true;
    tertiary: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sidebar: true;
    header: true;
  }
  interface ButtonPropsVariantOverrides {
    icon: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    code: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    code: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    code?: React.CSSProperties;
  }
}

declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    metrics: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tag: true;
    badge: true;
    filter: true;
  }
}

export const onCreateTheme = (mode: PaletteMode) => {
  const isLightMode = mode === 'light';
  const palette = isLightMode ? createLightPalette() : createDarkPalette();

  return createTheme({
    palette: {
      ...palette,
      sidebar: {
        main: '#FFFFFF',
      },
      header: {
        main: '#FFFFFF',
      },

      divider: palette.greys[50],
    },
    spacing: BASE_SPACING,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1300,
      },
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontWeight: 600,
            width: '32px',
            height: '32px',
          },
          circular: {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.main,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: palette.common.white,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cssVar('--border-radius-m'),
          },
        },
        defaultProps: {
          elevation: 0,
        },
        variants: [
          {
            props: { color: 'metrics' },
            style: {
              backgroundColor: palette.greys[50],
              color: palette.greys[500],
            },
          },
        ],
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: { variant: 'body1', fontWeight: 600 },
          subheaderTypographyProps: { variant: 'caption', color: 'greys.500' },
        },
        styleOverrides: {
          root: {
            flex: 'auto',
            minHeight: '52px',
            padding: getSpacing(4, 4, 0),
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            gap: getSpacing(1),
          },
          action: {
            margin: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: getSpacing(2),
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            ':last-child': {
              paddingBottom: `${getSpacing(4)} !important`,
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: getSpacing(0, 4, 4),
            ':empty': {
              display: 'none',
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            gap: getSpacing(1),
            'li:first-of-type': {
              marginRight: getSpacing(2),
            },
            'li:last-of-type': {
              marginLeft: getSpacing(2),
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            borderRadius: 'var(--border-radius-s)',
            margin: 0,
            color: palette.greys[500],
            '&.Mui-selected': {
              backgroundColor: palette.greys[900],
              color: palette.common.white,
              '&:hover': {
                backgroundColor: palette.greys[900],
              },
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            borderRadius: cssVar('--border-radius'),
            minWidth: '230px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: getSpacing(4),
            paddingTop: important(getSpacing(4)),
          },
        },
      },
      MuiDialogActions: {
        defaultProps: {
          disableSpacing: true,
        },
        styleOverrides: {
          root: {
            padding: getSpacing(4),
            justifyContent: 'flex-start',
            borderTop: `1px solid ${palette.divider}`,
            gap: getSpacing(2),
            '& > :last-child': {
              marginLeft: getSpacing(1),
            },
            alignItems: 'center',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            body: {
              backgroundColor: cssVar('--body-background'),
              color: palette.greys[700],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.6',
              minWidth: '20rem',
              '& > .SnackbarContent-root': {
                '#notistack-snackbar': {
                  paddingLeft: '60px',
                },
              },
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            backgroundColor: palette.greys[100],
          },
          root: {
            color: `${palette.primary.main} !important`,
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            alignItems: 'center',
            gap: getSpacing(1),
            borderRadius: 'var(--border-radius-m)',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
            '&:focus-visible': {
              outline: `1px solid ${palette.primary.main}`,
            },
          },
          sizeLarge: {
            height: '44px',
            minWidth: '44px',
            padding: getSpacing(0, 4),
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          sizeMedium: {
            height: '36px',
            minWidth: '36px',
            padding: getSpacing(0, 3),
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          sizeSmall: {
            height: '24px',
            minWidth: '24px',
            padding: getSpacing(0, 2),
            fontSize: '0.75rem',
            lineHeight: '1rem',
            borderRadius: 'var(--border-radius-s)',
          },
          endIcon: {
            margin: 0,
          },
          startIcon: {
            margin: 0,
          },
        },
        variants: [
          {
            props: { color: 'header', variant: 'contained' },
            style: {
              backgroundColor: palette.greys[50],
              color: palette.greys[900],
              '&:hover': {
                outline: `1px solid ${palette.primary.main}`,
              },
            },
          },
          {
            props: { color: 'sidebar', variant: 'contained' },
            style: {
              // this is does not change from light to dark theme
              backgroundColor: cssVar('--color-grey-900'),
              color: '#FFFFFF',
              '&:hover': {
                outline: `1px solid ${palette.primary.main}`,
              },
            },
          },
          {
            props: props =>
              (props.variant === 'contained' || props.variant === 'icon') &&
              props.color === 'primary',
            style: {
              backgroundColor: palette.blues[500],
              color: palette.common.white,
              '&:hover': {
                backgroundColor: palette.blues[600],
              },
              '&:active': {
                backgroundColor: palette.blues[700],
              },
            },
          },
          {
            props: props =>
              (props.variant === 'contained' || props.variant === 'icon') &&
              props.color === 'secondary',
            style: {
              backgroundColor: palette.blues[100],
              color: palette.blues[800],
              '&:hover': {
                backgroundColor: palette.blues[200],
              },
              '&:active': {
                backgroundColor: palette.blues[300],
              },
            },
          },
          {
            props: props =>
              (props.variant === 'contained' || props.variant === 'icon') &&
              props.color === 'tertiary',
            style: {
              backgroundColor: palette.common.white,
              color: palette.blues[500],
              '&:hover': {
                backgroundColor: palette.blues[50],
              },
              '&:active': {
                backgroundColor: palette.blues[100],
              },
            },
          },
          {
            props: props =>
              (props.variant === 'contained' || props.variant === 'icon') &&
              props.color === 'error',
            style: {
              backgroundColor: palette.reds[500],
              color: palette.common.white,
              '&:hover': {
                backgroundColor: palette.reds[600],
              },
              '&:active': {
                backgroundColor: palette.reds[700],
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              height: 'auto',
              minWidth: 'auto',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                color: palette.blues[600],
              },
              '&:active': {
                color: palette.blues[700],
              },
              '&:disabled': {
                color: palette.greys[300],
              },
            },
          },
          {
            props: { variant: 'icon' },
            style: {
              padding: 0,
              aspectRatio: 1,
            },
          },
          {
            props: { disabled: true },
            style: {
              backgroundColor: palette.greys[100],
              color: palette.greys[300],
            },
          },
        ],
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: cssVar('--color-grey-900'),
          },
          root: {
            '@media (min-width: 56.25rem)': {
              display: 'none',
            },
          },
          paperAnchorTop: {
            top: '3.75rem',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '100px',
            transition: 'none',
          },
        },
        variants: [
          {
            props: { color: 'header' },
            style: {
              color: palette.greys[500],
              backgroundColor: palette.greys[50],
            },
          },
        ],
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: 'var(--border-radius-m)',
            transition: 'background-color 0.2s ease-in-out',
            backgroundColor: palette.common.white,
            '&:hover:not(.Mui-focused):not(.Mui-disabled)': {
              backgroundColor: palette.blues[50],
            },
            '&.Mui-error': {
              outline: `1px solid ${palette.reds[500]}`,
            },
            '&.Mui-focused': {
              outline: `1px solid ${palette.blues[500]}`,
            },
          },
          notchedOutline: {
            display: 'none',
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          size: 'small',
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 'var(--border-radius-m)',
            transition: 'background-color 0.2s ease-in-out',
            backgroundColor: palette.greys[50],
            '&:hover:not(.Mui-focused):not(.Mui-disabled)': {
              backgroundColor: palette.greys[100],
            },
            '&.Mui-error': {
              backgroundColor: palette.greys[50],
              outline: `1px solid ${palette.reds[500]}`,
            },
            '&.Mui-focused': {
              backgroundColor: palette.greys[50],
              outline: `1px solid ${palette.blues[500]}`,
            },
            '&.Mui-disabled': {
              backgroundColor: palette.greys[50],
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
          variant: 'filled',
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            padding: getSpacing(0, 2),
          },
          select: {
            paddingRight: important(getSpacing(5)),
            backgroundColor: 'transparent',
          },
          icon: {
            color: palette.greys[500],
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            padding: getSpacing(1),
            paddingRight: `${getSpacing(1)} !important`,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderRadius: 'var(--border-radius-s)',
            padding: getSpacing(2),
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            transition: 'background-color 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: palette.greys[50],
            },
            '&.Mui-selected': {
              backgroundColor: palette.blues[100],
            },
            '&.Mui-selected:hover': {
              backgroundColor: palette.blues[200],
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: important('auto'),
            marginRight: getSpacing(2),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: getSpacing(4),
            gap: getSpacing(6),
            minHeight: '36px',
            borderWidth: '0 0 1px 0',
            borderStyle: 'solid',
            borderColor: palette.divider,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: cssVar('--border-radius-l'),
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: 'transparent',
            color: theme.palette.greys[400],
            border: '0',
            borderRadius: '0',
            height: 'auto',
            padding: '0',
            margin: 0,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          }),
          positionStart: {
            marginRight: getSpacing(2),
          },
          positionEnd: {
            marginLeft: getSpacing(2),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: getSpacing(0, 3),
            '&.Mui-disabled': {
              '& > *:not(input)': {
                opacity: 0.3,
              },
            },
          },
          input: {
            padding: important(getSpacing(2.5, 0)),
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            '::placeholder': {
              color: palette.greys[400],
              opacity: 1,
            },
          },
          inputSizeSmall: {
            padding: important(getSpacing(2, 0)),
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            display: 'grid',
            overflowX: 'auto',
            gridColumnGap: getSpacing(4),
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            display: 'contents',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            display: 'contents',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ theme }) => ({
            display: 'grid',
            gridColumn: '1 / -1',
            gridTemplateColumns: 'subgrid',
            borderBottom: `1px solid ${theme.palette.divider}`,
          }),
          head: {
            backgroundColor: palette.greys[50],
            borderRadius: 'var(--border-radius-s)',
            border: 'none',
            paddingLeft: getSpacing(3),
            paddingRight: getSpacing(3),
            margin: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            padding: getSpacing(4, 0),
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            '&.MuiTableCell-alignRight': {
              justifyContent: 'flex-end',
            },
            '&.MuiTableCell-alignLeft': {
              justifyContent: 'flex-start',
            },
          },
          head: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            color: palette.greys[500],
            padding: getSpacing(2, 0),
            border: 'none',
            svg: {
              marginTop: '-10px',
              marginBottom: '-10px',
            },
          },
        },
      },
      MuiChip: {
        defaultProps: {
          variant: 'tag',
        },
        styleOverrides: {
          root: {
            borderRadius: 'var(--border-radius-s)',
            fontSize: '0.75rem',
            lineHeight: '1rem',
          },
          label: {
            padding: 0,
          },
        },
        variants: [
          {
            props: { variant: 'tag' },
            style: {
              height: '24px',
              padding: getSpacing(0, 2),
              fontWeight: 600,
            },
          },
          {
            props: { variant: 'badge' },
            style: {
              height: '20px',
              padding: getSpacing(0, 1.5),
              fontWeight: 400,
            },
          },
          {
            props: { variant: 'filter' },
            style: {
              height: '24px',
              backgroundColor: palette.greys[100],
              color: palette.greys[800],
              padding: getSpacing(0, 2),
              ':hover': {
                backgroundColor: palette.greys[200],
              },
              ':focus': {
                backgroundColor: palette.greys[200],
              },
              '& .MuiChip-deleteIcon': {
                margin: getSpacing(0, 0, 0, 1),
                color: palette.greys[800],
                '&:hover': {
                  color: palette.greys[900],
                },
              },
            },
          },
          {
            props: { variant: 'tag', color: 'info' },
            style: {
              backgroundColor: palette.common.white,
              color: palette.greys[800],
            },
          },
          {
            props: { variant: 'tag', color: 'primary' },
            style: {
              backgroundColor: palette.blues[100],
              color: palette.blues[800],
            },
          },
          {
            props: { variant: 'tag', color: 'success' },
            style: {
              backgroundColor: palette.greens[100],
              color: palette.greens[800],
            },
          },
          {
            props: { variant: 'tag', color: 'warning' },
            style: {
              backgroundColor: palette.oranges[100],
              color: palette.oranges[800],
            },
          },
          {
            props: { variant: 'tag', color: 'error' },
            style: {
              backgroundColor: palette.reds[100],
              color: palette.reds[800],
            },
          },
          {
            props: { variant: 'tag', color: 'secondary' },
            style: {
              backgroundColor: palette.greys[100],
              color: palette.greys[800],
            },
          },
          {
            props: { variant: 'badge', color: 'info' },
            style: {
              backgroundColor: palette.common.white,
              color: palette.greys[600],
            },
          },
          {
            props: { variant: 'badge', color: 'primary' },
            style: {
              backgroundColor: palette.blues[50],
              color: palette.blues[500],
            },
          },
          {
            props: { variant: 'badge', color: 'success' },
            style: {
              backgroundColor: palette.greens[50],
              color: palette.greens[600],
            },
          },
          {
            props: { variant: 'badge', color: 'warning' },
            style: {
              backgroundColor: palette.oranges[50],
              color: palette.oranges[600],
            },
          },
          {
            props: { variant: 'badge', color: 'error' },
            style: {
              backgroundColor: palette.reds[50],
              color: palette.reds[600],
            },
          },
          {
            props: { variant: 'badge', color: 'secondary' },
            style: {
              backgroundColor: palette.greys[50],
              color: palette.greys[500],
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            width: '14px',
            height: '14px',
            display: 'grid',
            borderRadius: 'var(--border-radius-xs)',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            outlineColor: palette.greys[200],
            padding: 0,
            margin: '3px',
            transition: 'background-color 0.1s, color 0.1s 0.1s, outline 0.1s',
            color: palette.blues[500],
            '&:hover': {
              outlineColor: palette.greys[300],
              background: 'transparent',
            },
            '&:focus-within': {
              outlineColor: palette.greys[400],
            },
            '&.Mui-checked': {
              backgroundColor: palette.blues[500],
              outlineColor: palette.blues[500],
              color: palette.common.white,
            },
            '&:has(:focus-visible)': {
              outlineColor: palette.blues[600],
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            width: '16px',
            height: '16px',
            display: 'grid',
            borderRadius: '16px',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            outlineColor: palette.greys[200],
            padding: 0,
            margin: '2px',
            transition: 'all 0.1s',
            color: palette.common.white,
            '&:hover': {
              outlineColor: palette.greys[300],
              background: 'transparent',
            },
            '&:focus-within': {
              outlineColor: palette.greys[400],
            },
            '&.Mui-checked': {
              color: palette.blues[500],
              outlineColor: palette.blues[500],
              outlineOffset: '-4.5px',
              outlineWidth: '5.5px',
            },
            '&:has(:focus-visible)': {
              outlineColor: palette.blues[600],
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: getSpacing(1),
            height: getSpacing(1),
            backgroundColor: palette.greys[100],
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          rounded: {
            borderRadius: cssVar('--border-radius-l'),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 'var(--border-radius-l)',
          },
          elevation: {
            boxShadow: '0px 8px 24px 0px #030E121A',
          },
          elevation0: {
            boxShadow: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            gap: getSpacing(1),
            margin: '0 !important',
            ':has(.Mui-disabled)': {
              opacity: 0.5,
            },
          },
          label: {
            color: palette.greys[500],
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: getSpacing(9),
            height: getSpacing(5),
            padding: 0,
          },
          switchBase: {
            padding: 0,
            margin: getSpacing(0.5),
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                backgroundColor: palette.blues[500],
                opacity: 1,
              },
            },
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible': {
              '& + .MuiSwitch-track': {
                backgroundColor: palette.greys[400],
              },
            },
            '&:hover + .MuiSwitch-track': {
              backgroundColor: palette.greys[300],
            },
          },
          thumb: {
            width: getSpacing(4),
            height: getSpacing(4),
            boxShadow: 'none',
            backgroundColor: palette.common.white,
          },
          track: {
            borderRadius: getSpacing(5),
            backgroundColor: palette.greys[200],
            transition: 'background-color 0.2s',
            opacity: 1,
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: getSpacing(4),
              height: getSpacing(4),
            },
            '&::before': {
              diplay: 'block',
              width: getSpacing(0.5),
              height: getSpacing(2),
              backgroundColor: palette.common.white,
              left: getSpacing(2),
            },
            '&::after': {
              diplay: 'block',
              width: getSpacing(2),
              height: getSpacing(2),
              borderRadius: '50%',
              border: `${getSpacing(0.5)} solid ${palette.common.white}`,
              right: getSpacing(1.5),
            },
          },
        },
      },
    },
    typography: {
      fontFamily: 'Inter,sans-serif',
      fontSize: 14,
      h1: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
      },
      h2: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '2rem',
        lineHeight: '2.5rem',
      },
      h3: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '1.75rem',
        lineHeight: '2.25rem',
      },
      h4: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
      h5: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
      h6: {
        fontFamily: 'ModernEra-ExtraBold,sans-serif',
        color: palette.greys[900],
        fontSize: '1.125rem',
        lineHeight: '1.625rem',
      },
      body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
      caption: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
      button: {
        textTransform: 'none',
      },
      code: {
        color: palette.greys[900],
        fontFamily: 'Roboto Mono,monospace',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
  });
};
