import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const useSetRouteTitle = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // TODO switch to using useMatches/route.handle when we switch to a data-based router
  // https://reactrouter.com/en/main/routers/picking-a-router#using-v64-data-apis
  // It will allow us to specify the title together with the route definition instead of
  // these conditions
  useEffect(() => {
    let title = '';

    if (pathname.startsWith('/n/sites/')) {
      title = t('manage_site');
    } else if (pathname.startsWith('/n/sites')) {
      title = t('sites');
    } else if (pathname.startsWith('/n/dashboard')) {
      title = t('dashboard');
    } else if (pathname.startsWith('/n/sites/templates')) {
      title = t('site_templates');
    }

    document.title = title ? `${title} - Rocket.net` : 'Rocket.net';
  }, [pathname]);
};
